.App {
  text-align: center;
  background-image: url(/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  --color-link: #1352d1;
  --color-link-hover: #111;
}

.Header-links-box {
  font-size: calc(5px + 3vmin);
}

.Paper-box {
  margin-top: 5%;
  margin-bottom: 5%;
  width: 80%;
}
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Tangerine&display=swap');

.Main-header {
  font-size: calc(5px + 6vmin);
  font-family: 'Cormorant Garamond', serif;
}

.Main-content {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 5%;
  text-align: left;
  font-size: calc(9px + 2vmin);
  font-family: 'Cormorant Garamond', serif;
}

.Rsvp-content {
}

a {
    text-decoration: none;
    color: var(--color-link);
    outline: none;
}

a:hover {
    color: var(--color-link-hover);
    outline: none;
}

/* https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
    /* Provide a fallback style for browsers
	 that don't support :focus-visible */
    outline: none;
    background: lightgrey;
}

a:focus:not(:focus-visible) {
    /* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
    background: transparent;
}

a:focus-visible {
    /* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
    outline: 2px solid red;
    background: transparent;
}
