*,
*::after,
*::before {
    box-sizing: border-box;
}

:root {
    font-size: 16px;
}

body {
    margin: 0;
    --color-text: #111;
    --color-number: #999;
    --color-bg: #fff;
    --color-link: #1352d1;
    --color-link-hover: #111;
    color: var(--color-text);
    background-color: var(--color-bg);
    font-family: europa, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: var(--color-link);
    outline: none;
}

a:hover {
    color: var(--color-link-hover);
    outline: none;
}

a:focus {
    outline: none;
    background: lightgrey;
}

a:focus:not(:focus-visible) {
    background: transparent;
}

a:focus-visible {
    outline: 2px solid red;
    background: transparent;
}


.link {
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    color: var(--color-text);
}

.link::before,
.link::after {
    position: absolute;
    width: 100%;
    height: 1px;
    background: currentColor;
    top: 100%;
    left: 0;
    pointer-events: none;
}

.link::before {
    content: '';
}

.link--metis {
}

.link--metis::before {
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
}

.link--metis:hover::before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
}
