@import url(https://fonts.googleapis.com/css2?family=Alex+Brush&family=Tangerine&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  background-image: url(/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  --color-link: #1352d1;
  --color-link-hover: #111;
}

.Header-links-box {
  font-size: calc(5px + 3vmin);
}

.Paper-box {
  margin-top: 5%;
  margin-bottom: 5%;
  width: 80%;
}

.Main-header {
  font-size: calc(5px + 6vmin);
  font-family: 'Cormorant Garamond', serif;
}

.Main-content {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 5%;
  text-align: left;
  font-size: calc(9px + 2vmin);
  font-family: 'Cormorant Garamond', serif;
}

.Rsvp-content {
}

a {
    text-decoration: none;
    color: var(--color-link);
    outline: none;
}

a:hover {
    color: var(--color-link-hover);
    outline: none;
}

/* https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
    /* Provide a fallback style for browsers
	 that don't support :focus-visible */
    outline: none;
    background: lightgrey;
}

a:focus:not(:focus-visible) {
    /* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
    background: transparent;
}

a:focus-visible {
    /* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
    outline: 2px solid red;
    background: transparent;
}

*,
*::after,
*::before {
    box-sizing: border-box;
}

:root {
    font-size: 16px;
}

body {
    margin: 0;
    --color-text: #111;
    --color-number: #999;
    --color-bg: #fff;
    --color-link: #1352d1;
    --color-link-hover: #111;
    color: var(--color-text);
    background-color: var(--color-bg);
    font-family: europa, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: var(--color-link);
    outline: none;
}

a:hover {
    color: var(--color-link-hover);
    outline: none;
}

a:focus {
    outline: none;
    background: lightgrey;
}

a:focus:not(:focus-visible) {
    background: transparent;
}

a:focus-visible {
    outline: 2px solid red;
    background: transparent;
}


.link {
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    color: var(--color-text);
}

.link::before,
.link::after {
    position: absolute;
    width: 100%;
    height: 1px;
    background: currentColor;
    top: 100%;
    left: 0;
    pointer-events: none;
}

.link::before {
    content: '';
}

.link--metis {
}

.link--metis::before {
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-transform: scale3d(0, 1, 1);
            transform: scale3d(0, 1, 1);
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.link--metis:hover::before {
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
}

